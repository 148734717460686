.react-aria-Label {
    
}

.react-aria-Label span {
    color: red;
}

.react-aria-Text {
    
}

[slot=description] {
    margin-bottom: 8px;
    font-size: 14px;
    color: #adb5bd;
}