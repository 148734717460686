.react-aria-TextField {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    .react-aria-Input,
    .react-aria-TextArea {
        margin: 0;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid #444; 
  
      &[data-focused] {
        /* outline: 2px solid var(--focus-ring-color);
        outline-offset: -1px; */
      }
    }
  
    .react-aria-Input,
    .react-aria-TextArea {
      font-family: "VL Arch Light Cond Light";
      font-size: 16px;

      &[data-invalid] {
        /* border-color: var(--invalid-color); */
      }

      &::placeholder {
        font-size: 16px;
        color: #adb5bd;
      }
    }
  
    .react-aria-FieldError {
      /* font-size: 12px;
      color: var(--invalid-color); */
    }
  
    [slot=description] {
      margin-bottom: 8px;
    }
  
    .react-aria-Input,
    .react-aria-TextArea {
      &[data-disabled] {
        /* border-color: var(--border-color-disabled);
        color: var(--text-color-disabled); */
      }
    }
  }